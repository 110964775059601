import client from "@client";
import { formatOriginUrl } from "@utils/formatters";
export const env = import.meta.env;

/**
 * Bundler flags
 */
export const isProduction = env.PROD;
export const isDevelopment = env.DEV;
export const envMode = env.MODE;

/**
 * Client identifier
 */
export const clientId = env.VITE_CLIENT_ID;

/**
 * Application version set in the package.json
 */
export const appVersion = env.VITE_APP_VERSION;

/**
 * Backend api url
 */
export const apiUrlOriginacaoDefault = env.VITE_REACT_APP_BASE_URL;
export const apiUrlOriginacaoV2 = env.VITE_REACT_APP_BASE_URL_V2;

function getApiUrlOriginacao() {
  const urlParams = new URLSearchParams(window.location.search);
  const apiUrlParam = urlParams.get("apiUrl");

  if (env.MODE === "production") return apiUrlOriginacaoDefault;

  if (apiUrlParam === "v2") {
    localStorage.setItem("apiUrlOriginacao", "v2");
    return apiUrlOriginacaoV2;
  } else if (apiUrlParam === "v1") {
    localStorage.removeItem("apiUrlOriginacao");
    return apiUrlOriginacaoDefault;
  }

  const storedApiUrl = localStorage.getItem("apiUrlOriginacao");

  if (storedApiUrl === "v2") {
    return apiUrlOriginacaoV2;
  } else {
    return apiUrlOriginacaoDefault;
  }
}

export const apiUrlOriginacao = getApiUrlOriginacao();

/**
 * AWS api url
 */
export const apiUrlAws = env.VITE_API_VALID_IMAGE_URL;

/**
 * Backend websocket url
 */
export const apiUrlOriginacaoWs = env.VITE_REACT_APP_BASE_URL_WS;

/**
 * Card exclusive URL
 */
export const cardExclusiveUrl = formatOriginUrl(env.VITE_CARD_EXCLUSIVE_URL);
const isCardExclusiveEnv = location.origin === cardExclusiveUrl;

/**
 * Enable API mocks
 */
export const enableApiMocks = env.VITE_ENABLE_API_MOCKS === "1";

/**
 * Unico SDK settings - unico-webframe
 */
export const unico = isCardExclusiveEnv
  ? ({
      projectNumber: env.VITE_CARD_EXCLUSIVE_UNICO_PROJECT_NUMBER,
      projectId: env.VITE_CARD_EXCLUSIVE_UNICO_PROJECT_ID,
      mobileSdkAppId: env.VITE_CARD_EXCLUSIVE_UNICO_MOBILE_SDK_APP_ID,
      hostname: env.VITE_CARD_EXCLUSIVE_UNICO_HOSTNAME,
      hostInfo: env.VITE_CARD_EXCLUSIVE_UNICO_HOST_INFO,
      hostKey: env.VITE_CARD_EXCLUSIVE_UNICO_HOST_KEY,
    } as const)
  : ({
      projectNumber: env.VITE_UNICO_PROJECT_NUMBER,
      projectId: env.VITE_UNICO_PROJECT_ID,
      mobileSdkAppId: env.VITE_UNICO_MOBILE_SDK_APP_ID,
      hostname: env.VITE_UNICO_HOSTNAME,
      hostInfo: env.VITE_UNICO_HOST_INFO,
      hostKey: env.VITE_UNICO_HOST_KEY,
    } as const);

/**
 * New Relic settings
 */
export const newRelic = {
  applicationId: env.VITE_NEW_RELIC_APPLICATION_ID,
  licenceKey: env.VITE_NEW_RELIC_LICENSE_KEY,
  accountId: env.VITE_NEW_RELIC_ACCOUNT_ID,
} as const;

/**
 * Smartlook client key
 */
export const smartlookClientKey = env.VITE_SMARTLOOK_CLIENT_KEY;

/**
 * Clarity client key
 */
export const clarityClientKey = env.VITE_CLARITY_CLIENT_KEY;

/**
 * Static Client Settings with apiKeys object set from environment variables
 */
export const clientSettings: ClientSettingsWithKeys = {
  ...client,
  apiKeys: {
    unico: unico,
    newRelic: newRelic,
    smartlook: {
      clientKey: smartlookClientKey,
    },
    clarity: {
      clientKey: clarityClientKey,
    },
  },
};


export const vhagarServiceWappBaseUrl = env.VITE_VHAGAR_SERVICE_WAPP_BASE_URL;
export const vhagarServiceWappApiToken = env.VITE_VHAGAR_SERVICE_WAPP_API_TOKEN;
export const vhagarServiceWappValidateHash = env.VITE_VHAGAR_SERVICE_WAPP_VALIDATE_HASH;
export const vhagarServiceWappCallback = env.VITE_VHAGAR_SERVICE_WAPP_CALLBACK;
