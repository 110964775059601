export enum ATTACHMENT_TYPE {
  CCB = 1,
  DOCUMENTO_FRENTE = 2,
  DOCUMENTO_VERSO = 3,
  SELFIE = 4,
  FOTO_PROVA_DE_VIDA = 5,
  COMPROVANTE_DE_ENDERECO = 6,
  COMPROVANTE_FINANCEIRO = 7,
  CNH = 8,
  REPASSE = 11,
  DOCUMENTOS_ADICIONAIS = 12,
  FRENTE_CNH = 13,
  VERSO_CNH = 14,
  TERMO_E_ASSINATURAS = 15,
  CONTRACHEQUE = 16,
  DOCUMENTO_ASSINADO_FISICAMENTE = 17,
  DOCUMENTO_FRENTE_ROGADO = 18,
  DOCUMENTO_VERSO_ROGADO = 19,
  CNH_ROGADO = 20,
  DOCUMENTO_FRENTE_TESTEMUNHA_1 = 9,
  DOCUMENTO_VERSO_TESTEMUNHA_1 = 10,
  CNH_TESTEMUNHA_1 = 21,
  DOCUMENTO_FRENTE_TESTEMUNHA_2 = 23,
  DOCUMENTO_VERSO_TESTEMUNHA_2 = 24,
  CNH_TESTEMUNHA_2 = 25,
  ARQUIVO_RETORNO_RESERVA = 22,
  DOCUMENTO_ASSINADO_FISICAMENTE_PORTABILIDADE = 26,
  DOCUMENTO_ASSINADO_FISICAMENTE_REFINANCIAMENTO = 27,
  DOCUMENTO_FRENTE_REPRESENTANTE_LEGAL = 28,
  DOCUMENTO_VERSO_REPRESENTANTE_LEGAL = 29,
  CNH_REPRESENTANTE_LEGAL = 30,
  SELFIE_REFORMALIZACAO = 31,
}

export enum CONTRACT_TYPE {
  OPERACAO_NOVA = 1,
  REFINANCIAMENTO = 2,
  REFIN_PORTABILIDADE = 3,
  PORTABILIDADE = 4,
  NOVO_AUMENTO_SALARIAL = 5,
  SAQUE_COMPLEMENTAR = 6,
}

export enum DOCUMENT_TYPE {
  RG = 1,
  CNH = 2,
  OUTROS = 3,
}

export enum PRODUCT_CODE {
  MARGEM_LIVRE = "540",
  CARTAO_BENEFICIO = "001",
  PORTABILIDADE = "002",
  SAQUE_COMPLEMENTAR = "003",
  PORTABILIDADE_REFIN = "004",
  CARTAO_CONSIGNADO = "005",
  REFINANCIAMENTO = "006",
}

export enum PRODUCT_TYPE {
  TODOS_PRODUTOS = 0,
  FGTS = 1,
  INSS_REPRESENTANTE_LEGAL = 2,
  CARTAO_BENEFICIO_REPRESENTANTE = 3,
  PAB = 4,
  INSS_CORBAN = 5,
  INSS = 6,
  CARTAO_BENEFICIO = 7,
  SIAPE = 8,
  EXERCITO = 9,
  MARINHA = 10,
  AERONAUTICA = 11,
  PORTABILIDADE = 12,
  CONSIGNADO = 13,
  SAQUE_COMPLEMENTAR = 14,
  CARTAO_CONSIGNADO = 15,
  MARGEM_LIVRE = 16,
  PORTABILIDADE_REFIN = 17,
  REFINANCIAMENTO = 18,
  MARGEM_LIVRE_AUMENTO = 20,
  REFIN_SAQUE_CARTAO = 21,
}

export enum PLANS_TYPE {
  PRATA = 1,
  OURO = 2,
  DIAMANTE = 3,
}

export enum WS_MESSAGE_TYPE {
  CONTRACTS_PROCESSED = "CONTRACTS_PROCESSED",
}

export enum EDUCATION_LEVEL {
  FUNDAMENTAL = 1,
  MEDIO = 2,
  SUPERIOR = 3,
  POS_GRADUADO = 4,
  ANALFABETO = 5,
}

export enum KINSHIP_LEVEL {
  PAI = 1,
  MAE = 2,
  FILHO = 3,
  CONJUGE = 4,
  IRMAO = 5,
}
