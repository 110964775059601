import { QueryFunction, MutationFunction } from "@tanstack/react-query";

export class ApiError extends Error {
  data: unknown = null;
  response: Response | null = null;

  constructor(message: string, data?: unknown, response?: Response) {
    super(message);
    this.name = this.constructor.name;
    this.data = data || null;
    this.response = response || null;
  }
}

export type BaseMutationFnOptions = RequestInit & { formData?: boolean };

const getDefaultHeaders = (formData?: boolean) => {
  return {
    Accept: "application/json",
    ...(formData ? {} : { "Content-Type": "application/json" }),
  };
};

const objToFormData = (obj: unknown) => {
  const formData = new FormData();

  if (obj && typeof obj === "object") {
    for (const key in obj) {
      formData.append(key, obj[key as keyof typeof obj]);
    }
  }

  return formData;
};

interface VhagarServiceWappErrorDetail {
  error_message?: string;
}

const responseHandler = async (response: Response) => {
  if (response.ok) {
    return response.json() as Promise<unknown>;
  } else {
    const error = (await response.json()) as unknown;
    let message = "";

    if (error) {
      
      if (typeof error === "string") {
        message = error;
      } else if (typeof error === "object") {
        if ("Erro" in error && typeof error.Erro === "string") {
          message = error.Erro;
        } else if ("msg" in error && typeof error.msg === "string") {
          message = error.msg;
        } else if ("detail" in error && typeof error.detail === "string") {
          message = error.detail;
        } else if ("detail" in error && typeof error.detail === "object") {          
          const detail = error.detail as VhagarServiceWappErrorDetail;
          message = detail.error_message ?? "";
        }
      }
    }

    throw new ApiError(message, error, response);
  }
};

// TODO: check method to send data as body or query params
export const getQueryFn = (baseUrl = "") => {
  return async function ({ queryKey, signal }) {
    const [endpoint, data, method, headers] = queryKey;

    const response = await fetch(`${baseUrl}${endpoint as string}`, {
      headers: {
        ...getDefaultHeaders(),
        ...(headers && typeof headers === "object" ? headers : {}),
      },
      method: typeof method === "string" ? method : "GET",
      body: data ? JSON.stringify(data) : undefined,
      signal,
    });

    return responseHandler(response);
  } as QueryFunction;
};

export const getMutationFn = <TData, TVariables>(
  baseUrl = "",
  action = "",
  method = "POST",
  options: BaseMutationFnOptions = {},
) => {
  return async function (variables) {
    const { formData, ...requestInit } = options;

    const response = await fetch(`${baseUrl}${action}`, {
      ...requestInit,
      method,
      headers: {
        ...getDefaultHeaders(formData),
        ...(requestInit.headers || {}),
      },
      body: formData ? objToFormData(variables) : JSON.stringify(variables),
    });

    return responseHandler(response);
  } as MutationFunction<TData, TVariables>;
};
