import { newRelic } from "@settings";

export const mapClientThemeSettings = (
  data: IApiClientThemeResponse,
  type: ClientSettingsThemeType = "default",
): ClientSettingsWithKeys => {
  const isDefaultTheme = type === "default";
  const settings =
    data[isDefaultTheme ? "formalizacao" : "formalizacao_cartao"];

  return {
    name: settings.titulo,
    in100authority: settings.autoridade,
    theme: {
      token: {
        colorPrimary: settings.cor_primaria,
      },
    },
    assets: {
      logo: settings.logo,
      favicon: settings.favicon,
      card: data.digitacao.imagem_do_cartao,
      consignedCard: data.digitacao.imagem_cartao_consignado,
      refinCard: "/refin-card.png",
      bankLogo: isDefaultTheme ? "/bank-logo.png" : "/pine-logo.png",
    },
    apiKeys: {
      newRelic: data.formalizacao.api_keys.new_relic_application_id
        ? {
            applicationId: data.formalizacao.api_keys.new_relic_application_id,
            accountId: data.formalizacao.api_keys.new_relic_account_id,
            licenceKey: data.formalizacao.api_keys.new_relic_license_key,
          }
        : newRelic,
      unico: {
        projectNumber: data.formalizacao.api_keys.unico_project_number,
        projectId: data.formalizacao.api_keys.unico_project_id,
        mobileSdkAppId: data.formalizacao.api_keys.unico_mobile_sdk_app_id,
        hostname: data.formalizacao.api_keys.unico_hostname,
        hostInfo: data.formalizacao.api_keys.unico_host_info,
        hostKey: data.formalizacao.api_keys.unico_host_key,
      },
      smartlook: {
        clientKey: data.formalizacao.api_keys.smartlook_client_key,
      },
      clarity: {
        clientKey: data.formalizacao.api_keys.clarity_client_key,
      },
    },
  };
};
