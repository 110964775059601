/**
 * Configurações específicas para o cliente "Happy"
 */
const settings: ClientSettings = {
  name: "Happy",
  in100authority: "o Banco Pine S/A",
  downloadTermText: "O HappyConsig",
  theme: {
    token: {
      colorPrimary: "#0097BA",
    },
  },
  assets: {
    logo: '/logo.png',
    favicon: '/favicon.png',
    card: '/card.png',
    refinCard: '/refin-card.png',
    consignedCard: '/consigned-card.png',
    bankLogo: '/bank-logo.png'
  },
  cardExclusiveTheme: {
    name: "Amigoz",
    theme: {
      token: {
        colorPrimary: "#00A2E0",
      },
    },
    assets: {
      logo: '/card-logo.png',
      favicon: '/favicon-card-exclusive.png',
      bankLogo: '/pine-logo.png'
    }
  }
};

export default settings;
